import React from 'react';
import { PathService } from '../../services';
import {
  FindARiskAdvisorOverviewViewContext,
  TemplateProps,
} from '../../types';
import { FindARiskAdvisorOverviewView } from '../../views';

const FindARiskAdvisorOverview: React.FunctionComponent<TemplateProps<
  FindARiskAdvisorOverviewViewContext
>> = ({ location, pageContext }) => {
  PathService.set(location.pathname);
  return (
    <FindARiskAdvisorOverviewView
      regions={JSON.parse(pageContext.regions)}
      directorsWithRegions={JSON.parse(pageContext.directorsWithRegions)}
    />
  );
};

export default FindARiskAdvisorOverview;
